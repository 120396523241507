import React from "react";
import Layout from "../../components/layout";
import AboutMenu from "../../components/about/menu";
import H1 from "../../components/h1";
import Seo from "../../components/seo";

const RirekiPage = () => {
  return (
    <Layout>
      <Seo title="水野真澄講演会履歴" />
      <main>
        <H1>会社概要</H1>
        <AboutMenu />
        <div className="bg-cyan-50 space-y-3 md:space-y-5 py-3 md:py-5">
          <div className="container mx-auto max-w-4xl py-5 md:py-10 px-3 md:px-5 bg-white">
            <div className="mx-auto max-w-2xl text-sm md:text-base divide-y border-gray-200">
              {seminars.map((s, index) => (
                <div
                  key={index}
                  className="flex py-5 text-sm md:text-base font-sans"
                >
                  <div className="w-1/4 md:w-1/5 mr-1 md:mr-2">
                    <div className="font-number font-light">{s.date}</div>
                    <div className="text-xs md:text-sm font-serif">
                      {s.place}
                    </div>
                  </div>
                  <div className="w-3/4 md:w-4/5">
                    <div className="text-xs md:text-sm mb-1 md:mb-2">
                      {s.host}
                    </div>
                    <div>{s.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default RirekiPage;

const seminars = [
  {
    date: "2021-11-01",
    place: "オンライン",
    host: "チェイスネクスト",
    title: "中国外貨管理セミナー",
  },
  {
    date: "2021-10-19",
    place: "オンライン",
    host: "企業研究会",
    title: "中国会計・税務セミナー",
  },
  {
    date: "2021-10-15",
    place: "上海",
    host: "上海日本商工クラブ",
    title: "外資企業の経営管理の誤解と現状",
  },
  {
    date: "2021-08-01",
    place: "オンライン",
    host: "チェイスネクスト",
    title: "税関・加工貿易・保税区域活用セミナー",
  },
  {
    date: "2021-07-01",
    place: "オンデマンド形式",
    host: "広島香港協会",
    title: "香港ビジネス環境の現状と未来",
  },
  {
    date: "2021-02-16",
    place: "オンライン",
    host: "日中投資促進機構",
    title: "企業管理に潜むリスクと実務上の注意点",
  },
  {
    date: "2021-02-01",
    place: "オンライン",
    host: "チェイスネクスト",
    title: "中国外貨管理マニュアルQ&A出版記念セミナー",
  },
  {
    date: "2020-09-11",
    place: "オンライン",
    host: "日本在外協会",
    title:
      "日本企業の対中ビジネス戦略（新型コロナと世界情勢が香港を中心とする対中戦略に及ぼす影響）",
  },
  {
    date: "2020-08-21",
    place: "オンライン",
    host: "太陽グラントソントン",
    title:
      "香港の機能と今後（日本企業の戦略における香港の位置付けと、海南自由貿易港）",
  },
  {
    date: "2020-02-13",
    place: "大阪",
    host: "大阪商工会",
    title:
      "これからの中国ビジネスに必要な視点（香港・東南アジア・米国との関係を踏まえて）",
  },
  {
    date: "2020-01-29",
    place: "東京",
    host: "企業研究会",
    title: "中国会計・税務セミナー",
  },
  {
    date: "2020-01-20",
    place: "福岡",
    host: "福岡銀行",
    title: "中国・香港ビジネス環境の現状と今後、日本企業はどう向き合うべきか",
  },
  {
    date: "2019-12-19",
    place: "東京",
    host: "企業研究会",
    title: "中国のPE課税と移転価格",
  },
  {
    date: "2019-12-17",
    place: "東京",
    host: "企業研究会",
    title: "中国外貨管理セミナー",
  },
  {
    date: "2019-12-11",
    place: "上海",
    host: "TSUBASAアライアンス（千葉銀行、第四北越銀行、北國銀行、中国銀行、伊予銀行、東邦銀行）",
    title: "中国における日系企業の今後のビジネス展開について",
  },
  {
    date: "2019-09-27",
    place: "東京",
    host: "香港貿易発展局",
    title:
      "日本と香港、さらなる飛躍へのパートナー（日本企業の香港活用モデルと香港の今後）",
  },
  {
    date: "2019-07-22",
    place: "東京",
    host: "企業研究会",
    title: "中国外貨管理セミナー",
  },
  {
    date: "2019-07-18",
    place: "横浜",
    host: "チェイスネクスト",
    title: "中国ビジネス担当者マニュアルステップワンセミナー",
  },
  {
    date: "2019-07-12",
    place: "恵州",
    host: "恵州日本商工会",
    title: "華南地区における日系企業進出の最新動向",
  },
  {
    date: "2019-06-26",
    place: "深圳",
    host: "水野コンサルタンシーグループ・アナシス共催",
    title: "中国における非居住者課税変更（2019年）",
  },
  {
    date: "2019-06-17",
    place: "横浜",
    host: "チェイスネクスト",
    title: "中国外貨管理セミナー",
  },
  {
    date: "2019-06-13",
    place: "東京",
    host: "企業研究会",
    title: "中国会計・税務セミナー",
  },
  {
    date: "2019-03-21",
    place: "上海",
    host: "信金中央金庫",
    title: "新個人所得税法への対応・出張者のビザ・個人所得税の取扱い方法",
  },
  {
    date: "2019-02-18",
    place: "上海",
    host: "水野コンサルタンシーグループ・明倫法律事務所共催",
    title: "中国からの利益回収モデル構築",
  },
  {
    date: "2019-01-30",
    place: "東京",
    host: "企業研究会",
    title: "外貨管理セミナー",
  },
  {
    date: "2018-12-03",
    place: "横浜",
    host: "チェイスネクスト",
    title: "中国会計・税務セミナー",
  },
  {
    date: "2018-11-22",
    place: "大阪",
    host: "太陽グラントソントン",
    title: "中国地域統括本部の沿革の実務",
  },
  {
    date: "2018-10-04",
    place: "東京",
    host: "日中投資促進機構",
    title: "中国実務者セミナー（外貨管理から見たビジネスモデル）",
  },
  {
    date: "2018-10-03",
    place: "東京",
    host: "企業研究会",
    title: "中国のPE課税と移転価格",
  },
  {
    date: "2018-09-14",
    place: "東莞",
    host: "東莞日本商工会",
    title: "中国における各種組織変更",
  },
  {
    date: "2018-09-11",
    place: "深圳",
    host: "政策金融公庫",
    title: "現行の税関・外貨管理制度が及ぼすビジネスモデルへの影響と対策",
  },
  {
    date: "2018-07-30",
    place: "東京",
    host: "企業研究会",
    title: "外貨管理セミナー",
  },
  {
    date: "2018-06-11",
    place: "東京",
    host: "企業研究会",
    title: "中国会計・税務セミナー",
  },
  {
    date: "2018-05-31",
    place: "東京",
    host: "企業研究会",
    title:
      "組織再編セミナー（中国における資金調達と組織再編・買収・撤退に関する外貨管理）",
  },
  {
    date: "2018-05-29",
    place: "",
    host: "チェイスネクスト",
    title: "中国ビジネス投資Q&Aセミナー",
  },
  {
    date: "2018-03-09",
    place: "深圳",
    host: "信金中央金庫",
    title:
      "最近の外貨管理制度変更に影響を受けた各種取引モデルと最新ビジネス動向",
  },
  {
    date: "2018-01-30",
    place: "東京",
    host: "企業研究会",
    title: "外貨管理セミナー",
  },
  {
    date: "2018-01-29",
    place: "横浜",
    host: "横浜銀行",
    title: "中国・ベトナム動向（中国の外資政策の歴史的経緯）",
  },
  {
    date: "2017-12-05",
    place: "広州",
    host: "広州日本商工会",
    title: "外貨管理制度の変更で対応可否が変わったビジネスモデル",
  },
  {
    date: "2017-10-05",
    place: "東京",
    host: "日中投資促進機構（実務者セミナー）",
    title: "中国の外貨管理（貿易取引・利益回収・資金調達）",
  },
  {
    date: "2017-10-04",
    place: "福岡",
    host: "福岡銀行",
    title: "現地概況パネルディスカッション",
  },
  {
    date: "2017-07-24",
    place: "東京",
    host: "企業研究会",
    title: "外貨管理セミナー",
  },
  {
    date: "2017-07-11",
    place: "広州",
    host: "水野コンサルタンシーグループ・金杜法律事務所",
    title: " 税関管理・税務・外貨管理から見た華南の組織再編",
  },
  {
    date: "2017-06-29",
    place: "上海",
    host: "日本商工クラブ",
    title: "新就業許可管理",
  },
  {
    date: "2017-06-09",
    place: "東京",
    host: "日本香港協会",
    title: "中国ビジネスから見た香港の機能の推移",
  },
  {
    date: "2017-06-07",
    place: "横浜",
    host: "チェイスネクスト",
    title: "中国の日系企業の現状",
  },
  {
    date: "2017-04-07",
    place: "東京",
    host: "日中投資促進機構",
    title:
      "中国の外資企業管理制度・税務・通関・外貨管理―最新動向と実務のポイント（2000年以降の経緯を踏まえて理解する）",
  },
  { date: "2017-03-15", place: "香港", host: "地銀会", title: "外貨管理動向" },
  {
    date: "2017-03-02",
    place: "深圳",
    host: "政策金融公庫",
    title: "通関管理・税関調査の注意点と行政処罰について",
  },
  {
    date: "2017-03-01",
    place: "福州",
    host: "福州日本商工会",
    title:
      "通関・保税管理（保税区域・加工貿易）における最新動向、会計・税務における最新動向",
  },
  {
    date: "2017-01-30",
    place: "東京",
    host: "企業研究会",
    title: "中国外貨管理の最新動向およびクロスボーダー人民元取引",
  },
  {
    date: "2016-12-15",
    place: "上海",
    host: "NNA主催中国ビジネス講座700回記念講演",
    title:
      "15年の経緯を踏まえて理解する中国の外資企業管理制度、税務、通関、外貨管理",
  },
  {
    date: "2016-10-28",
    place: "広州",
    host: "広州日本商工会・ジェトロ主催",
    title: "通関管理・税関調査の注意点と行政処罰について",
  },
  {
    date: "2016-10-05",
    place: "日本・東京",
    host: "日中投資促進機構主催",
    title: "外貨管理（貿易・非貿易の送金・換金）と資金調達",
  },
  {
    date: "2016-10-04",
    place: "日本・横浜",
    host: "チェイスチャイナ主催",
    title: "1日でマスターする中国ビジネス実務特別講座",
  },
  {
    date: "2016-09-05",
    place: "深圳・広州",
    host: "水野コンサルティング・ANAXIS共同主催",
    title: "今後3年間を見据えた組織づくりと利益配分",
  },
  {
    date: "2016-07-21",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "中国外貨管理の最新動向およびクロスボーダー人民元取引",
  },
  {
    date: "2016-07-08",
    place: "日本・東京",
    host: "広州市投資促進中心主催",
    title: "中国でのビジネスモデルと広州",
  },
  {
    date: "2016-07, 08",
    place: "日本・東京／横浜／名古屋／金沢",
    host: "PCA主催",
    title: "中国事業の注意点と不正・税務リスクの防止／中国流通税改革の概要",
  },
  {
    date: "2016-06-17",
    place: "日本・横浜",
    host: "チェイスチャイナ主催",
    title: "1日でマスターする中国ビジネス実務特別講座",
  },
  {
    date: "2016-06-08",
    place: "香港",
    host: "水野コンサルティング・金杜法律事務所共同主催",
    title: "税関管理・税務・外貨管理から見た華南の組織再編",
  },
  {
    date: "2016-04-27",
    place: "広州",
    host: "水野コンサルティング・金杜法律事務所共同主催",
    title: "広東省における組織運営と役割",
  },
  {
    date: "2016-03-09",
    place: "上海",
    host: "横浜市（横浜企業経営支援財団上海事務所）主催",
    title: "中国投資の現状とこれからの組織・ビジネスモデル",
  },
  {
    date: "2016-02-04",
    place: "日本・金沢",
    host: "中小企業基盤整備機構主催",
    title: "これからの中国事業展開と事業再編の考え方",
  },
  {
    date: "2016-02-03",
    place: "日本・富山",
    host: "中小企業基盤整備機構主催",
    title: "これからの中国事業展開と事業再編の考え方",
  },
  {
    date: "2016-02-02",
    place: "日本・福井",
    host: "中小企業基盤整備機構主催",
    title: "これからの中国事業展開と事業再編の考え方",
  },
  {
    date: "2016-01-29",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "中国外貨管理の最新動向およびクロスボーダー人民元取引",
  },
  {
    date: "2016-01-15",
    place: "広州",
    host: "自社主催・追加講演",
    title: "日本人董事・総経理が留意したい税務・資金活用上の注意点",
  },
  {
    date: "2016-01-14",
    place: "広州",
    host: "広州商工会商社分科会主催",
    title: "華南・ASEANのビジネスモデルの現状と今後の方向性",
  },
  {
    date: "2016-01-14",
    place: "広州",
    host: "自社主催",
    title: "日本人董事・総経理が留意したい税務・資金活用上の注意点",
  },
  {
    date: "2015-10-06",
    place: "日本・東京",
    host: "中小企業基盤整備機構主催",
    title: "中国における今後の事業展開及び利益拡大策",
  },
  {
    date: "2015-10-05",
    place: "日本・東京",
    host: "日中投資促進機構主催",
    title: "中国ビジネスモデルの再構築（貿易モデルと保税区域の活用方法）",
  },
  {
    date: "2015-09-16",
    place: "日本・富山",
    host: "北陸銀行主催",
    title: "中国市場の位置付けと今後のビジネスモデル構築",
  },
  {
    date: "2015-09-09",
    place: "上海",
    host: "ジェトロ上海主催",
    title: "製造業から見た貿易方式とビジネスモデル構築",
  },
  {
    date: "2015-07-29",
    place: "深圳",
    host: "深圳日本商工会・ジェトロ広州主催",
    title: "税関関連最新状況",
  },
  {
    date: "2015-07-10",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "中国外貨管理の最新動向およびクロスボーダー人民元取引",
  },
  {
    date: "2015-06-19",
    place: "広州",
    host: "みずほ銀行広州主催",
    title: "広東省と香港の組織体制・ビジネス構築上の注意点",
  },
  {
    date: "2015-06-11",
    place: "日本・香川",
    host: "ジェトロ香川主催",
    title: "事業再編のポイントとトラブル回避のための予防策",
  },
  {
    date: "2015-05-18",
    place: "上海",
    host: "日本政策金融公庫主催",
    title: "中国におけるビジネスモデル・組織の効率化",
  },
  {
    date: "2015-04-16",
    place: "日本",
    host: "日本経済研究センター主催",
    title: "変容する中国ビジネスー日本企業の進路",
  },
  {
    date: "2015-02-20",
    place: "日本",
    host: "早稲田大学商学学術院主催",
    title: "中国市場の位置付けと今後のビジネスモデル構築",
  },
  {
    date: "2015-02-04",
    place: "日本",
    host: "日中投資促進機構主催",
    title: "資金管理：「中国資金管理の実務」",
  },
  {
    date: "2015-02-02",
    place: "日本",
    host: "企業研究会主催",
    title:
      "中国外貨管理の最新動向およびクロスボーダー人民元取引（経常項目・投融資）活用に 関する日本企業の今後の対応",
  },
  {
    date: "2014-12-19",
    place: "上海",
    host: "水野商務諮詢上海有限公司主催",
    title:
      "税関・通関対応（ビジネスモデル・税関調査）のポイントと今後の税務調査の動向",
  },
  {
    date: "2014-12-16",
    place: "広州",
    host: "水野商務諮詢広州有限公司主催",
    title:
      "税関・通関対応（ビジネスモデル・税関調査）のポイントと今後の税務調査の動向",
  },
  {
    date: "2014-12-11",
    place: "香港",
    host: "商工中金香港駐在員事務所主催",
    title: "中国ビジネスの成功モデルと失敗モデル",
  },
  {
    date: "2014-11-28",
    place: "日本・横浜",
    host: "横浜市（横浜企業経営支援財団）主催",
    title: "中国事業におけるビジネス モデルの再構築",
  },
  {
    date: "2014-11-20",
    place: "深圳",
    host: "三井住友銀行深圳主催",
    title: "外資企業の組織再編",
  },
  {
    date: "2014-11-10",
    place: "蘇州",
    host: "蘇州日本クラブ・ジェトロ上海主催",
    title: "税関対応から見たビジネスモデルと税関調査のポイント",
  },
  {
    date: "2014-10-17",
    place: "上海",
    host: "信金中央金庫主催",
    title: "中国ビジネスでのトラブル事例と最新ビジネス動向",
  },
  {
    date: "2014-10-03",
    place: "日本・海老名市",
    host: "神奈川県主催",
    title: "実例解説！中国ビジネスの成功モデルと失敗モデル",
  },
  {
    date: "2014-09-24",
    place: "日本・東京",
    host: "在外研究会主催",
    title: "最近の税務調査のポイント",
  },
  {
    date: "2014-09-05",
    place: "深圳",
    host: "深圳日本商工会・ジェトロ広州主催",
    title: "最近の税務調査・税関調査のポイント",
  },
  {
    date: "2014-08-26",
    place: "上海",
    host: "ジェトロ上海主催",
    title: "中国（上海）自由貿易試験区の最新動向（通関）",
  },
  {
    date: "2014-07-25",
    place: "日本・東京",
    host: "チェイスチャイナ主催",
    title: "中国事業におけるビジネスモデルの再構築",
  },
  {
    date: "2014-07-23",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "国外貨管理の最新動向およびクロスボーダー人民元取引",
  },
  {
    date: "2014-06-29",
    place: "廈門",
    host: "廈門日本商工会・ジェトロ広州主催",
    title: "中国ビジネス最新動向",
  },
  {
    date: "2014-06-09",
    place: "香港",
    host: "ジェトロ香港主催",
    title: "中国からの利益還元方法と香港を活用したビジネスモデル",
  },
  {
    date: "2014-05-28",
    place: "日本・川崎市",
    host: "川崎市主催",
    title: "中国ビジネスにおける10の本当・嘘",
  },
  {
    date: "2014-05-24",
    place: "香港",
    host: "水野コンサルタンシーホールディングス主催",
    title: "加工貿易政策の今後と保税取引モデル・保税開発区の今後の展望",
  },
  {
    date: "2014-05-22",
    place: "広州",
    host: "水野商務諮詢広州主催",
    title: "加工貿易政策の今後と保税取引モデル・保税開発区の今後の展望",
  },
  {
    date: "2014-04-28",
    place: "上海",
    host: "水野商務諮詢上海有限公司主催",
    title: "中国ビジネス最新動向",
  },
  {
    date: "2014-03-27",
    place: "広州",
    host: "広州日本商工会・ジェトロ広州主催",
    title: "最近のビジネス制度変更と会社運営上の注意点",
  },
  {
    date: "2014-03-21",
    place: "深圳",
    host: "信金中央金庫主催",
    title: "中国事業のビジネスモデルの再構築",
  },
  {
    date: "2014-03-11",
    place: "香港",
    host: "香港日本商工会・化学品部会",
    title: "ビジネス制度の変更から見る今後の中国展開の注意点",
  },
  {
    date: "2014-03-06",
    place: "深圳",
    host: "政策金融公庫・深圳日本商工会主催",
    title: "最近の税務調査・税関調査のポイント",
  },
  {
    date: "2014-02-20",
    place: "広州",
    host: "水野商務諮詢広州有限公司主催",
    title: "中国ビジネス最新動向",
  },
  {
    date: "2014-01-30",
    place: "日本・東京",
    host: "日中投資促進機構主催",
    title: "中国実務者セミナー（貿易）",
  },
  {
    date: "2014-01-24",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "中国外貨管理の最新動向およびクロスボーダー人民元取引向",
  },
  {
    date: "2014-01-08",
    place: "北京",
    host: "北京日本商会・三資会主催",
    title: "ビジネス制度の変更から見る今後の中国展開の注意点",
  },
  {
    date: "2013-12-20",
    place: "日本・東京",
    host: "チェイスチャイナ主催",
    title: "地域統括会社機能（香港）と中国・ASEAN商流",
  },
  {
    date: "2013-12-18",
    place: "日本・東京",
    host: "みずほ総研主催",
    title: "中国ビジネスでのトラブル事例と問題解決・予防の具体策",
  },
  {
    date: "2013-11-07",
    place: "深圳",
    host: "水野商務諮詢広州有限公司主催",
    title: "深圳の税務・税関最新動向と前海湾の誘致政策",
  },
  {
    date: "2013-10-30",
    place: "蘇州",
    host: "蘇州日本商工会主催",
    title: "2013年ビジネス制度変更の注意点",
  },
  {
    date: "2013-09-26",
    place: "日本・東京",
    host: "金融研究会主催",
    title: "中国外貨管理の最新動向及びクロスボーダー人民元決済講座",
  },
  {
    date: "2013-08-21",
    place: "日本・東京",
    host: "日本経済新聞社主催",
    title: "最低限のリスクで中国市場から利益を獲得するビジネスモデルの構築方法",
  },
  {
    date: "2013-08-05",
    place: "日本・東京",
    host: "日中投資促進機構主催",
    title: "中国実務者セミナー（貿易）",
  },
  {
    date: "2013-07-23",
    place: "日本・東京",
    host: "企業研究会主催",
    title:
      "中国における外貨管理およびクロスボーダー人民元取引範囲の拡大（経常項目から投融資へ）による日本企業の今後の対応",
  },
  {
    date: "2013-07-19",
    place: "日本・東京",
    host: "太陽グラントソントン主催",
    title: "中国事業グループ合理化を目的とした日中間取引設計",
  },
  {
    date: "2013-07-18",
    place: "日本・大阪",
    host: "太陽グラントソントン主催",
    title: "中国事業グループ合理化を目的とした日中間取引設計",
  },
  {
    date: "2013-06-26, 27, 28",
    place: "上海",
    host: "NNA主催",
    title: "中国の外貨管理とビジネスモデル",
  },
  {
    date: "2013-06-20",
    place: "日本・東京",
    host: "みずほ総研主催",
    title: "最新　『中国外貨管理・人民元対外決済のしくみと実務』入門講座",
  },
  {
    date: "2013-03-01",
    place: "日本・東京",
    host: "ジェトロ主催",
    title: "中国輸出ビジネスセミナー（外貨管理、保税開発区の活用と加工貿易）",
  },
  {
    date: "2013-02-23",
    place: "蘇州",
    host: "蘇州商工会主催",
    title: "中国ビジネス最新動向",
  },
  {
    date: "2013-02-13",
    place: "日本・東京",
    host: "日中投資促進機構主催",
    title: "中国における外貨管理の理論と実務（初級・中級編）",
  },
  {
    date: "2013-02-07",
    place: "日本・東京",
    host: "チェイスチャイナ主催",
    title: "中国での組織運営・エグジットモデルと利益回収モデル",
  },
  {
    date: "2013-01-22",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "中国における外貨管理およびクロスボーダー人民元取引範囲の拡大",
  },
  {
    date: "2012-12-11",
    place: "日本・東京",
    host: "みずほ総研主催",
    title:
      "ビジネスモデルで解説する中国ビジネスにおける利益回収・税コスト・通関管理のポイント",
  },
  {
    date: "2012-12-04",
    place: "恵州",
    host: "恵州日本商工会主催",
    title: "今後の中国での販売・製造方針と組織の在り方",
  },
  {
    date: "2012-11-29",
    place: "日本・東京",
    host: "日本経済新聞社主催",
    title:
      "中国での販売・利益回収モデル構築と円滑な撤退・組織再編（初・中級編）",
  },
  {
    date: "2012-10-17",
    place: "日本・高松市",
    host: "香川県主催",
    title: "中国進出における方法論と各種リスク管理と問題解決方法",
  },
  {
    date: "2012-10-16",
    place: "日本・東京",
    host: "チェイスチャイナ主催",
    title: "中国の外貨管理",
  },
  {
    date: "2012-09-06",
    place: "日本・東京",
    host: "みずほ総研主催",
    title: "完全網羅！中国拠点の開設・組織変更・撤退の実務",
  },
  {
    date: "2012-08-28",
    place: "日本・東京",
    host: "チェイスチャイナ主催",
    title: "中国の外貨管理",
  },
  {
    date: "2012-08-22",
    place: "香港",
    host: "三井住友銀行香港主催",
    title:
      "デッドライン迫る！来料加工廠独資転換の注意点と転換後の経営のポイント",
  },
  {
    date: "2012-08-02",
    place: "上海",
    host: "外高橋保税区主催・水野商務諮詢上海有限公司共催",
    title: "外高橋保税区（上海総合保税区）の制作発表",
  },
  {
    date: "2012-07-31",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "中国における外貨管理およびクロスボーダー人民元取引範囲の拡大",
  },
  {
    date: "2012-07-24",
    place: "日本・東京",
    host: "水野コンサルタンシー・曽我法律事務所共催",
    title: "中国の組織再編",
  },
  {
    date: "2012-07-06",
    place: "日本・東京",
    host: "日本皮革連合会主催",
    title: "中国の進出形態",
  },
  {
    date: "2012-06-19",
    place: "日本・東京",
    host: "みずほ総研主催",
    title: "1日で分かる中国外貨管理・人民元対外決済の実務知識",
  },
  {
    date: "2012-05-24",
    place: "日本・東京",
    host: "日本香港協会主催",
    title: "中国・アジア戦略における香港の位置づけと活用",
  },
  {
    date: "2012-04-16",
    place: "日本・横浜",
    host: "横浜市主催（横浜市企業経営四川財団）",
    title: "流通税改革・外貨管理制度の変革",
  },
  {
    date: "2012-03-23",
    place: "広州",
    host: "広州日本商工会・ジェトロ広州主催",
    title: "上海市における流通税制度の改革（営業税と増値税の統合）",
  },
  {
    date: "2012-03-22",
    place: "広州",
    host: "水野商務諮詢広州有限公司主催",
    title: "流通税改革・今後の加工貿易政策",
  },
  {
    date: "2012-03-15",
    place: "深圳",
    host: "政策金融公庫主催",
    title: "今後の加工貿易制度",
  },
  {
    date: "2012-02-24",
    place: "日本・東京",
    host: "企業研究会",
    title: "日本企業の中国ビジネススキーム構築方法",
  },
  {
    date: "2012-02-21",
    place: "日本・名古屋",
    host: "東海日中貿易センター主催",
    title: "加工貿易最新事情",
  },
  {
    date: "2012-01-24",
    place: "日本・横浜",
    host: "横浜市主催（横浜市企業経営支援財団）",
    title: "中国最新ビジネス情報と今後の市場対策",
  },
  {
    date: "2012-01-23",
    place: "日本・東京",
    host: "日本皮革連合会主催",
    title: "中国の外貨管理",
  },
  {
    date: "2012-01-20",
    place: "日本・東京",
    host: "日中投資促進機構主催",
    title: "中国における課税強化の内容とその対応策",
  },
  {
    date: "2012-01-17",
    place: "日本・東京",
    host: "日本皮革連合会主催",
    title: "中国の進出形態",
  },
  {
    date: "2012-01-13",
    place: "香港",
    host: "香港日本商工会・化学品部会主催",
    title: "来料加工廠の独資転換",
  },
  {
    date: "2011-12-09",
    place: "日本・東京",
    host: "チェイスチャイナ主催",
    title:
      "中国ビジネスモデルの税務・外貨管理・通関面からの検証 （鈴木康伸会計士とのパネルディスカッション形式）",
  },
  {
    date: "2011-11-30",
    place: "日本・東京",
    host: "日本経済新聞社主催",
    title:
      "中国での販売・利益回収モデル構築と円滑な撤退・組織再編（初・中級編）",
  },
  {
    date: "2011-11-14",
    place: "日本・東京",
    host: "日本経済新聞社主催",
    title: "1日で分かる中国外貨管理・人民元対外決済の実務知識",
  },
  {
    date: "2011-11-10",
    place: "日本・東京",
    host: "フジサンケイビジネスアイ主催",
    title: "中国・アジア戦略における香港の位置づけと活用",
  },
  {
    date: "2011-11-01",
    place: "日本・東京",
    host: "チェイスチャイナ主催",
    title:
      "中国企業との提携におけるリスク管理と問題解決方法 （劉新宇弁護士とのパネルディスカッション形式）",
  },
  {
    date: "2011-10-26",
    place: "大連",
    host: "日本経済新聞社主催",
    title: "加工貿易の今後と最近のビジネス環境の変化",
  },
  {
    date: "2011-08-26",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "中国における外貨管理およびクロスボーダー人民元取引範囲の拡大",
  },
  {
    date: "2011-07-06",
    place: "香港",
    host: "香港日本商工会・化学品部会主催",
    title: "来料加工廠の独資転換",
  },
  {
    date: "2011-06-29",
    place: "香港",
    host: "水野コンサルタンシーホールディングス主催",
    title: "1日で分かる中国外貨管理の実務知識",
  },
  {
    date: "2011-05-31",
    place: "上海",
    host: "水野商務諮詢上海有限公司・NMC共催",
    title: "中国ビジネス最新動向",
  },
  {
    date: "2011-03-24",
    place: "上海",
    host: "静岡県主催（静岡国際経済上海事務所）",
    title:
      "外資企業関連制度のアップデート（課税強化、外貨管理制度変更、その他）",
  },
  {
    date: "2011-03-15",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "1日で分かる中国外貨管理の実務知識",
  },
  {
    date: "2011-03-11",
    place: "日本・東京",
    host: "日中投資促進機構主催",
    title: "中国における課税強化の内容とその対応策",
  },
  {
    date: "2011-03-08",
    place: "日本・大阪",
    host: "大阪商工会議所主催",
    title: "中国における課税強化の内容とその対応策",
  },
  {
    date: "2011-03-03",
    place: "深圳",
    host: "政策金融公庫主催",
    title: "加工貿易制度の今後に付いて",
  },
  {
    date: "2011-01-07",
    place: "東莞",
    host: "ジェトロ広州主催",
    title: "来料加工廠の独資転換の注意点と転換後の運営について",
  },
  {
    date: "2010-12-06",
    place: "香港",
    host: "三井住友銀行香港主催",
    title: "クロスボーダー人民元による今後の香港・華南ビジネス",
  },
  {
    date: "2010-11-10",
    place: "日本・東京",
    host: "企業研究会主催",
    title: "最近の対中貿易をめぐる中国での課題と具体的な実務対応",
  },
  {
    date: "2010-10-13",
    place: "日本・東京",
    host: "香港貿易発展局主催",
    title:
      "人民元の自由化と外貨管理の動向・アジアビジネスにおける香港の位置付け",
  },
  {
    date: "2010-10-06",
    place: "日本・東京",
    host: "水野コンサルタンシー・曽我瓜生糸賀法律事務所主催",
    title:
      "中国における買収・組織再編・日本からの技術支援に関する法務・税務戦略セミナー",
  },
  {
    date: "2010-08-26",
    place: "香港",
    host: "香港日本商工会・繊維部会主催",
    title:
      "人民元の自由化と外貨管理の動向・アジアビジネスにおける香港の位置付け",
  },
  {
    date: "2010-08-18",
    place: "香港",
    host: "銀友会主催",
    title: "中国における課税強化の現状・対応と今後の外貨管理の展望",
  },
  {
    date: "2010-06-26",
    place: "日本・東京",
    host: "香港稲門会主催",
    title: "香港華南ビジネスの最新動向",
  },
  {
    date: "2010-05-31",
    place: "広州",
    host: "水野商務諮詢広州有限公司主催",
    title: "税務セミナー",
  },
  {
    date: "2010-05-21",
    place: "上海",
    host: "上海芙蓉会主催",
    title: "外国企業・外資企業に対する管理の推移",
  },
  {
    date: "2010-05-07",
    place: "香港",
    host: "香港日本商工会・運輸部会主催",
    title: "中国における課税強化の現状と注意点",
  },
  {
    date: "2010-03-18",
    place: "上海",
    host: "信金中央金庫主催",
    title: "徴税強化の動きを中心とした最近の税務問題",
  },
  {
    date: "2010-03-10",
    place: "深圳",
    host: "ジェトロ広州主催",
    title: "来料加工廠独資転換作業と転換後のオペレーション",
  },
  {
    date: "2010-03-09",
    place: "広州",
    host: "ジェトロ広州主催",
    title: "来料加工廠独資転換作業と転換後のオペレーション",
  },
  {
    date: "2010-01-19",
    place: "香港",
    host: "水野コンサルタンシー・NNA主催",
    title: "中国での課税強化の動き、香港に関わる中国事業組織変更",
  },
  {
    date: "2010-01-05",
    place: "香港",
    host: "香港日本商工会・化学品部会主催",
    title: "来料加工の今後と独資転換のタイミングに付いて",
  },
  {
    date: "2009-12-05",
    place: "広州・南沙",
    host: "南沙保税港区管理委員会主催・水野商務諮詢広州協賛",
    title: "南沙保税港区の政策発表",
  },
  {
    date: "2009-10-21",
    place: "香港",
    host: "三井住友銀行香港主催",
    title: "中国・華南ビジネスの最新情報と今後の動向",
  },
  {
    date: "2009-09-08",
    place: "香港",
    host: "信金中央金庫主催",
    title: "加工貿易企業の国内販売の展開に付いて",
  },
  {
    date: "2009-07-17",
    place: "深圳",
    host: "ジェトロ広州主催",
    title: "加工貿易企業の国内販売の展開に付いて（JETRO講演・深圳）",
  },
  {
    date: "2009-06-22",
    place: "香港",
    host: "NNA主催",
    title: "加工貿易制度の現状と今後の展望",
  },
  {
    date: "2009-06-17",
    place: "日本・大阪",
    host: "香港貿易発展局主催",
    title: "中国ビジネスにおける香港の位置づけ",
  },
  {
    date: "2009-04-24",
    place: "香港",
    host: "商工中金主催",
    title: "中国ビジネス最新動向",
  },
  {
    date: "2009-03-17",
    place: "日本・東京",
    host: "香港貿易発展局主催",
    title: "中国ビジネス最新動向（加工貿易制度の今後、その他）",
  },
  {
    date: "2009-03-04",
    place: "上海",
    host: "水野コンサルタンシー・NNA共催",
    title: "保税開発区活用とPE認定リスク",
  },
  {
    date: "2009-02-19",
    place: "広州",
    host: "広州保税区管理委員会主催・水野商務諮詢広州協賛",
    title: "広州保税物流園区政策発表",
  },
  {
    date: "2009-02-04",
    place: "日本・東京",
    host: "日中投資促進機構主催",
    title: "外資政策の変更と加工貿易制度を取り巻く環境変化",
  },
  {
    date: "2008-11-26",
    place: "深圳",
    host: "水野コンサルタンシー・NACグローバル・EH共催",
    title: "操業を止めない来料加工廠の独資転換",
  },
  {
    date: "2008-11-17",
    place: "香港",
    host: "香港貿易発展局主催",
    title: "香港・中国本土進出の注意点",
  },
  {
    date: "2008-10-20",
    place: "日本・大阪",
    host: "大阪商工会議所主催",
    title: "中国ビジネス戦略の転換・再構築と会社組織変更",
  },
  {
    date: "2008-09-22",
    place: "香港",
    host: "香港日本商工会・運輸部会",
    title: "保税開発区を活用したビジネスモデル",
  },
];
